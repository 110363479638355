import React from 'react';
import axios from 'axios';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { prefixer } from 'stylis';

import isEmail from 'validator/lib/isEmail';

import { Container, Box, Stack, Typography, Button, FormControl, FormControlLabel, TextField, CircularProgress, Snackbar, Alert, Checkbox, FormGroup, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';

interface IFriendObj {
	fullname: string;
	phoneNumber: string;
	email: string;
	role: string;
	cv: string | File | null;
}

interface IFormObj {
	fullname: string;
	phoneNumber: string;
	email: string;
	idNumber: string;
	agreeTerms: boolean;

	friends: IFriendObj[];
}

interface FriendProps {
	idx: number;
	formObj: IFormObj;
	errorsObj: Partial<IFormObj>;
	allRoles: IRoleObj[];
	handleChangeFriend: (idx: number, fieldKey: keyof IFriendObj, newVal: string, newFile?: File | null) => void;
	handleRemoveFriend: (idx: number) => void;
	isDisabled?: boolean;
}

interface snackbarDataProps {
	open: boolean;
	severity: 'error' | 'success';
	msg?: string;
}

interface IRoleObj {
	jobTitle: string;
	jobCode: string;
}

const cacheLtr = createCache({
	key: 'muiltr',
});

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

const themeComponents = {
	MuiButton: {
		styleOverrides: {
			root: {
				'&.MuiButton-contained': {
					fontSize: '24px',
					fontWeight: 'bold',
				},
				'&.MuiButton-text': {
					textDecoration: 'underline',
					fontSize: '20px',
					fontWeight: 'bold',
				},
			},
		},
	},
	MuiFormLabel: {
		styleOverrides: {
			root: {
				'&.Mui-error': {
					fontWeight: 'bold',
				},
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				fontWeight: 'bold',
				fontSize: '14px',
			},
		},
	},
	// MuiTextField: {
	// 	styleOverrides: {
	// 		root: {
	// 			'& .MuiOutlinedInput-root.Mui-error': {
	// 				'& fieldset': {
	// 					borderWidth: '2px',
	// 				},
	// 			},
	// 		},
	// 	},
	// },
};

const theme = {
	palette: {
		primary: {
			main: '#51253e',
		},
	},
	typography: {
		fontFamily: 'Open Sans, Arial',
	},
	components: themeComponents,
};

const ltrTheme = createTheme({ ...theme, direction: 'ltr' });
const rtlTheme = createTheme({ ...theme, direction: 'rtl' });

rtlTheme.typography.h1 = {
	fontSize: '30px',
	[rtlTheme.breakpoints.up('md')]: {
		fontSize: '40px',
	},
};
rtlTheme.typography.subtitle1 = {
	fontWeight: 'bold',
	fontSize: '30px',
	lineHeight: '1.2',
};
rtlTheme.typography.subtitle2 = {
	fontWeight: '500',
	fontSize: '16px',
	lineHeight: '18px',
	[rtlTheme.breakpoints.up('md')]: {
		fontSize: '24px',
		lineHeight: '30px',
	},
};

const FriendLine = (props: FriendProps) => {
	return (
		<Stack spacing="20px" sx={{ mb: '25px' }}>
			<Typography variant="subtitle1">
				פרטי החבר/ה:
				{props.idx > 0 && (
					<Button size="small" onClick={() => props.handleRemoveFriend(props.idx)}>
						(הסר/י חבר/ה)
					</Button>
				)}
			</Typography>
			<Stack spacing="15px" justifyContent="center" alignItems="center" direction={{ xs: 'column', md: 'row' }}>
				{/* <Button sx={{ visibility: props.idx === 0 ? 'hidden' : 'visible' }} onClick={() => props.handleRemoveFriend(props.idx)}>
					X
				</Button> */}
				<TextField disabled={props.isDisabled} fullWidth error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['fullname'])} label="שם מלא" name="fullname" value={props.formObj?.friends && props.formObj.friends[props.idx]['fullname']} onChange={(event) => props.handleChangeFriend(props.idx, 'fullname', event.target.value)} />
				<TextField disabled={props.isDisabled} fullWidth error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['phoneNumber'])} label="טלפון" name="phoneNumber" value={props.formObj?.friends && props.formObj.friends[props.idx]['phoneNumber']} onChange={(event) => props.handleChangeFriend(props.idx, 'phoneNumber', event.target.value)} inputProps={{ minLength: '9', maxLength: '10' }} />
				<TextField disabled={props.isDisabled} fullWidth error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['email'])} label='דוא"ל' name="email" value={props.formObj?.friends && props.formObj.friends[props.idx]['email']} onChange={(event) => props.handleChangeFriend(props.idx, 'email', event.target.value)} />
				{/* <TextField disabled={props.isDisabled} fullWidth error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['role'])} label="לאיזה תפקיד?" name="role" value={props.formObj?.friends && props.formObj.friends[props.idx]['role']} onChange={(event) => props.handleChangeFriend(props.idx, 'role', event.target.value)} /> */}
				<FormControl disabled={props.isDisabled} fullWidth error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['role'])}>
					<InputLabel id={`select-label-${props.idx}`}>לאיזה תפקיד?</InputLabel>
					<Select labelId={`select-label-${props.idx}`} id={`select-${props.idx}`} value={props.formObj?.friends && props.formObj.friends[props.idx]['role']} label="לאיזה תפקיד?" onChange={(event) => props.handleChangeFriend(props.idx, 'role', event.target.value)}>
						{props.allRoles.map((role, idx) => (
							<MenuItem key={`role-${idx}`} value={role.jobCode}>
								{role.jobTitle}
							</MenuItem>
						))}
					</Select>
					<FormHelperText></FormHelperText>
				</FormControl>
			</Stack>
			<Box>
				<FormControl sx={{ alignItems: 'start' }} error={!!(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['cv'])}>
					<Button disabled={props.isDisabled} size="small" variant="text" component="label">
						צרף/י קורות חיים של החבר/ה
						<input type="file" accept=".doc, .docx, .pdf, .txt" hidden onChange={(event) => props.handleChangeFriend(props.idx, 'cv', '', event.target?.files && event.target.files[0])} />
					</Button>
					<span>{props.formObj?.friends && props.formObj.friends[props.idx] && props.formObj.friends[props.idx]['cv'] && (props.formObj.friends[props.idx]['cv'] as File).name}</span>
					<FormHelperText>{(props.errorsObj?.friends && props.errorsObj.friends[props.idx] && props.errorsObj.friends[props.idx]['cv']) as string}</FormHelperText>
				</FormControl>
			</Box>
		</Stack>
	);
};

const clientCommands = axios.create({
	baseURL: 'https://niloo-server.herokuapp.com',
	// baseURL: 'http://localhost:3030',
});

function App() {
	// const [isRtl, setIsRtl] = React.useState(true);
	const isRtl = true;

	const [uniqueKey, setUniqueKey] = React.useState(Date.now());

	const [allRoles, setAllRoles] = React.useState<IRoleObj[]>([]);

	const [isSent, setIsSent] = React.useState(false);

	const [submitInProgress, setSubmitInProgress] = React.useState(false);

	const [snackbarData, setSnackbarData] = React.useState<snackbarDataProps>({
		open: false,
		severity: 'error',
		msg: '',
	});

	const [formObj, setFormObj] = React.useState<IFormObj>({
		fullname: '',
		phoneNumber: '',
		email: '',
		idNumber: '',
		agreeTerms: false,

		friends: [
			{
				fullname: '',
				phoneNumber: '',
				email: '',
				role: '',
				cv: null,
			},
		],
	});

	const [errorsObj, setErrorsObj] = React.useState<Partial<IFormObj>>({});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		setFormObj({ ...current, [fieldKey]: val });
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).checked;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		setFormObj({ ...current, [fieldKey]: val });
	};

	const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		// to allow only digits
		const regexTerm = /^[0-9\b]+$/;
		if (val === '' || regexTerm.test(val)) {
			setFormObj({ ...current, [fieldKey]: val });
		}
	};

	const handleChangeFriend = (idx: number, fieldKey: keyof IFriendObj, newVal: string, newFile?: File | null) => {
		const current = formObj;

		const currentFriendObj = formObj?.friends || [];

		if (fieldKey === 'cv') {
			if (newFile) {
				currentFriendObj[idx][fieldKey] = newFile;
			} else {
				currentFriendObj[idx][fieldKey] = null;
			}
		} else if (fieldKey === 'phoneNumber') {
			const regexTerm = /^[0-9\b]+$/;
			if (newVal === '' || regexTerm.test(newVal)) {
				currentFriendObj[idx][fieldKey] = newVal;
			}
		} else {
			currentFriendObj[idx][fieldKey] = newVal;
		}

		setFormObj({ ...current, friends: currentFriendObj });
	};

	const validateId = (idValue: string) => {
		const trimmed = idValue.trim();
		if (trimmed.length < 5 || trimmed.length > 9) {
			return false;
		}
		const id = trimmed.length < 9 ? ('00000000' + trimmed).slice(-9) : trimmed;
		const count = Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		});
		return count && count % 10 === 0;
	};

	const validatePhone = (phoneValue: string) => {
		if (phoneValue.length !== 10) {
			return false;
		}
		const startsBy = ['050', '051', '052', '053', '054', '055', '058'];
		const substring = phoneValue.substring(0, 3);

		return startsBy.includes(substring);
	};

	const validateUniEmail = (emailValue: string) => {
		const endsBy = ['@huji.ac.il', '.huji.ac.il'];
		const substring = emailValue.substring(emailValue.length - 11);

		return isEmail(emailValue) && endsBy.includes(substring);
	};

	const validateAllFields = React.useCallback(() => {
		let errors: any = {};
		let friendsErrors: any[] = [];

		let fields: string[] = ['fullname', 'phoneNumber', 'email', 'idNumber', 'agreeTerms'];
		let friendFields: string[] = ['fullname', 'phoneNumber', 'email', 'role', 'cv'];

		for (const field of fields) {
			const val = formObj[field as keyof typeof formObj];
			if (!val) {
				// errors[field] = 'This field is required.';
				errors[field] = 'שדה חובה';
			}
			if (field === 'email' && !validateUniEmail(val as string)) {
				// errors[field] = 'This email is invalid.';
				// errors[field] = 'שדה הדוא"ל לא תקין';
				errors[field] = 'דוא"ל אוניברסיטאי לא תקין';
			}
			if (field === 'phoneNumber' && !validatePhone(val as string)) {
				// errors[field] = 'This phone number is invalid.';
				errors[field] = 'שדה הטלפון לא תקין';
			}
			if (field === 'idNumber' && !validateId(val as string)) {
				// errors[field] = 'This phone number is invalid.';
				errors[field] = 'שדה תעודת זהות לא תקין';
			}
		}

		for (let index = 0; index < formObj.friends.length; index++) {
			const friend = formObj.friends[index];
			for (const friendField of friendFields) {
				const val = friend[friendField as keyof IFriendObj];
				if (!val) {
					if (!friendsErrors[index]) {
						friendsErrors[index] = {};
					}
					// friendsErrors[index][friendField] = 'This field is required.';
					friendsErrors[index][friendField] = 'שדה חובה';
				}
				const fileExt = (friendField === 'cv' && val && (val as File).name?.split('.')?.pop()?.toLowerCase()) || '';
				if (friendField === 'cv' && val && !['doc', 'docx', 'pdf', 'txt'].includes(fileExt)) {
					if (!friendsErrors[index]) {
						friendsErrors[index] = {};
					}
					// friendsErrors[index][friendField] = 'This cv type is invalid.';
					friendsErrors[index][friendField] = 'קובץ קורות החיים נדרש להיות מסמך מסוג doc, docx, pdf, txt';
				}
				if (friendField === 'email' && !isEmail(val as string)) {
					if (!friendsErrors[index]) {
						friendsErrors[index] = {};
					}
					// friendsErrors[index][friendField] = 'This email is invalid.';
					friendsErrors[index][friendField] = 'שדה הדוא"ל לא תקין';
				}
				if (friendField === 'phoneNumber' && !validatePhone(val as string)) {
					if (!friendsErrors[index]) {
						friendsErrors[index] = {};
					}
					// friendsErrors[index][friendField] = 'This phone number is invalid.';
					friendsErrors[index][friendField] = 'שדה הטלפון לא תקין';
				}
			}
		}

		if (friendsErrors.length) {
			errors.friends = friendsErrors;
		}

		if (isSent) {
			setErrorsObj(errors);
		}

		return errors;
		// return Object.keys(errors).length;
	}, [formObj, isSent]);

	const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarData({ ...snackbarData, open: false });
	};

	const resetForm = () => {
		setFormObj({
			fullname: '',
			phoneNumber: '',
			email: '',
			idNumber: '',
			agreeTerms: false,

			friends: [
				{
					fullname: '',
					phoneNumber: '',
					email: '',
					role: '',
					cv: null,
				},
			],
		});

		setUniqueKey(Date.now());

		setIsSent(false);
	};

	const addFriend = () => {
		const newFriend = {
			fullname: '',
			phoneNumber: '',
			email: '',
			role: '',
			cv: null,
		};

		const current = formObj;

		const currentFriendObj = formObj?.friends || [];

		currentFriendObj.push(newFriend);

		setFormObj({ ...current, friends: currentFriendObj });
	};

	const removeFriend = (idx: number) => {
		const current = formObj;

		const currentFriendObj = formObj?.friends || [];

		currentFriendObj.splice(idx, 1);

		setFormObj({ ...current, friends: currentFriendObj });
	};

	const sendCommand = async (cmd: string, formData: FormData) => {
		let result;

		// const commandData = {
		// 	cmd,
		// 	formObj: JSON.stringify(formObj),
		// };

		formData.append('cmd', cmd);

		try {
			let response = await clientCommands.post('/actions-hamah', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (response.data) {
				result = response.data;
			} else {
				console.log('no response data');
			}
		} catch (error) {
			console.log(error);
		}

		return result;
	};

	const submitForm = async () => {
		console.log('submitForm');

		setIsSent(true);
		const errors = validateAllFields();
		const errorsCount = Object.keys(errors).length;

		if (errorsCount > 0) {
			console.log('errorsCount', errorsCount, errors);
		} else {
			setSubmitInProgress(true);

			try {
				if (formObj?.friends?.length > 0) {
					for (const friend of formObj.friends) {
						const { cv, fullname, phoneNumber, email, role } = friend;
						if (cv && role) {
							let formData = new FormData();
							formData.append('file', cv);
							formData.append('fullname', fullname);
							formData.append('phoneNumber', phoneNumber);
							formData.append('email', email);
							formData.append('role', role);
							formData.append('recommend_fullname', formObj.fullname);
							formData.append('recommend_phoneNumber', formObj.phoneNumber);
							formData.append('recommend_email', formObj.email);
							formData.append('recommend_idNumber', formObj.idNumber);

							const sendRes = await sendCommand('send-cvmail', formData);

							console.log(sendRes);
						}
					}
					setSnackbarData({ open: true, severity: 'success', msg: 'נשלח בהצלחה' });
					resetForm();
				} else {
					console.log('no friends');
				}
			} catch (error) {
				console.log(error);
				setSnackbarData({ open: true, severity: 'error', msg: 'אירעה שגיאה' });
			}

			setSubmitInProgress(false);
		}
	};

	const getJobs = React.useCallback(async () => {
		let result;

		const commandData = {
			cmd: 'get-jobs',
		};

		try {
			let response = await clientCommands.post('/actions-hamah', commandData);

			if (response.data) {
				result = response.data;
			} else {
				console.log('no response data');
			}
		} catch (error) {
			console.log(error);
		}

		return result;
	}, []);

	const fetchAllRoles = React.useCallback(async () => {
		const roles = await getJobs();
		setAllRoles(roles);
	}, [getJobs]);

	React.useEffect(() => {
		validateAllFields();
	}, [validateAllFields, formObj]);

	React.useEffect(() => {
		fetchAllRoles();
	}, [fetchAllRoles]);

	React.useLayoutEffect(() => {
		document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
	}, [isRtl]);

	return (
		<CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
			<ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
				<CssBaseline />
				<Box sx={{ backgroundColor: '#51253e', py: { xs: '30px', md: '40px' }, color: '#ffffff', textAlign: 'center' }}>
					<Container>
						<Stack spacing="10px" justifyContent="center" alignItems="center" direction={{ xs: 'column', md: 'row' }}>
							<Typography variant="h1">ת'כלס, </Typography>
							<Typography variant="h1">בשביל מה יש חברים?</Typography>
						</Stack>
					</Container>
				</Box>

				<Stack alignItems="stretch" justifyContent="center">
					<img style={{ width: '100%', maxHeight: '100%' }} src="img/cover.png" alt="Cover" />
				</Stack>

				<Box sx={{ backgroundColor: '#e7c375', py: { xs: '30px', md: '40px' } }}>
					<Container>
						<Stack spacing="20px" justifyContent="space-evenly" alignItems="center" direction={{ xs: 'column', md: 'row' }}>
							<Stack spacing="10px" justifyContent="center" alignItems="center">
								<img src="/img/contact.svg" alt="contact icon" />
								<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
									מלאו את פרטי החבר/ה
								</Typography>
								<Typography variant="subtitle2">בתחתית העמוד</Typography>
							</Stack>
							<Stack spacing="10px" justifyContent="center" alignItems="center">
								<img src="/img/send.svg" alt="send icon" />
								<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
									שלחו לנו
								</Typography>
								<Typography variant="subtitle2">את הפרטים</Typography>
							</Stack>
							<Stack spacing="10px" justifyContent="center" alignItems="center">
								<img src="/img/gift.svg" alt="gift icon" />
								<Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
									אם החבר/ה יתקבלו לעבודה
								</Typography>
								<Typography variant="subtitle2">תרוויחו תווי שי מתנה!</Typography>
							</Stack>
						</Stack>
					</Container>
				</Box>

				<Box sx={{ backgroundColor: '#ffffff', py: { xs: '30px', md: '40px' }, fontSize: { xs: '16px', md: '24px' } }}>
					<Container>
						{formObj?.friends.map((_friend, idx) => (
							<FriendLine key={`${uniqueKey}-${idx}`} isDisabled={submitInProgress} idx={idx} formObj={formObj} handleChangeFriend={handleChangeFriend} handleRemoveFriend={removeFriend} errorsObj={errorsObj} allRoles={allRoles} />
						))}
						<Stack justifyContent="center" alignItems={{ xs: 'center', md: 'start' }}>
							<Button disabled={submitInProgress} sx={{ maxWidth: '300px', mb: '30px' }} fullWidth variant="contained" onClick={addFriend}>
								עוד חבר/ה
							</Button>
						</Stack>
					</Container>
				</Box>

				<Box>
					<Container>
						<Stack spacing="20px" sx={{ mb: '25px' }}>
							<Typography variant="subtitle1">הפרטים שלי:</Typography>
							<Stack spacing="15px" justifyContent="center" alignItems="center" direction={{ xs: 'column', md: 'row' }}>
								<TextField disabled={submitInProgress} fullWidth error={!!errorsObj?.fullname} label="שם מלא" name="fullname" value={formObj?.fullname} onChange={handleChange} />
								<TextField disabled={submitInProgress} fullWidth error={!!errorsObj?.phoneNumber} label="טלפון" name="phoneNumber" value={formObj?.phoneNumber} onChange={handleNumberChange} inputProps={{ minLength: '9', maxLength: '10' }} />
								<TextField disabled={submitInProgress} fullWidth error={!!errorsObj?.email} label='דוא"ל אוניברסיטאי' name="email" value={formObj?.email} onChange={handleChange} />
								<TextField disabled={submitInProgress} fullWidth error={!!errorsObj?.idNumber} label='מספר ת"ז' name="idNumber" value={formObj?.idNumber} onChange={handleNumberChange} inputProps={{ minLength: '5', maxLength: '9' }} />
							</Stack>
							<Box>
								<FormControl disabled={submitInProgress} error={!!errorsObj?.agreeTerms}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox name="agreeTerms" checked={formObj?.agreeTerms} onChange={handleCheckboxChange} />}
											label={
												<Box>
													<span style={{ fontSize: '20px', lineHeight: '1.75', verticalAlign: 'middle' }}>אני מצהיר/ה כי קראתי ואני מסכים/ה ל</span>
													{/* <Typography variant="button">אני מצהיר/ה כי קראתי ואני מסכים/ה ל</Typography> */}
													<Button href="./agreement.pdf" target="_blank" sx={{ p: '0' }} size="small" variant="text">
														תנאי התקנון
													</Button>
												</Box>
											}
										/>
									</FormGroup>
									<FormHelperText>{errorsObj?.agreeTerms}</FormHelperText>
								</FormControl>
							</Box>
							<Stack justifyContent="center" alignItems={{ xs: 'center', md: 'start' }}>
								<Button sx={{ maxWidth: '300px', my: '40px' }} fullWidth variant="contained" size="large" onClick={submitForm} disabled={submitInProgress}>
									{submitInProgress ? <CircularProgress size="27px" /> : 'שליחה'}
								</Button>
							</Stack>
						</Stack>
					</Container>
				</Box>

				<Stack justifyContent="center" alignItems={{ xs: 'center', md: 'end' }}>
					<img src="img/logo.png" alt="Logo" style={{ maxWidth: '100%' }} />
				</Stack>

				<Box sx={{ backgroundColor: '#51253e', py: { xs: '30px', md: '40px' }, color: '#ffffff', textAlign: 'center', fontSize: { xs: '30px', md: '40px' } }}>
					<Container>
						<Typography>POWERED BY NILOOSOFT HUNTER EDGE</Typography>
					</Container>
				</Box>

				<Snackbar sx={{ minWidth: '350px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarData.open} autoHideDuration={8000} onClose={handleSnackbarClose}>
					<Alert onClose={handleSnackbarClose} elevation={6} variant="filled" severity={snackbarData.severity} sx={{ width: '100%', fontSize: '20px', justifyContent: 'center', alignItems: 'center' }}>
						{snackbarData?.msg}
					</Alert>
				</Snackbar>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default App;
